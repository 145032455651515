export const elements = [
    {
        id: 1,
        key: 'dashboard',
        name: "Dashboard",
        path: "/app/admin",
        icon: "/admin-icons/sidebar/dashboard.svg",
        // external: "https://irevu.com/index.php"
    },
    {
        id: 2,
        key: 'reviews',
        name: "Reviews",
        path: "/app/admin/reviews",
        icon: "/admin-icons/sidebar/reviews.svg",
        children: [
            // {
            //     id: 1,
            //     subKey: "manageAutoPilot",
            //     name: "Autopilot AI",
            //     path: "/app/admin/reviews/autopilotAi",
            //     fullService: true
            // },
            {
                id: 2,
                subKey: "manageReviews",
                name: "Manage Reviews",
                path: "/app/admin/reviews/list",
            },
            {
                id: 3,
                subKey: "manageTags",
                name: "Manage Tags",
                path: "/app/admin/reviews/tags",
            },
            {
                id: 4,
                subKey: "manageSources",
                name: "Review Sources",
                path: "/app/admin/reviews/sources",
            },
            {
                id: 5,
                subKey: "manageConnections",
                name: "Connections",
                path: "/app/admin/reviews/connections",
            }
        ]
    },
    {
        id: 3,
        key: 'autopilot',
        name: "Autopilot AI",
        path: "/app/admin/autopilot",
        icon: "/admin-icons/sidebar/ai.svg",
        children: [
            {
                id: 1,
                subKey: "manageAutoPilot",
                name: "Details",
                path: "/app/admin/autopilot/details",
            },
            {
                id: 2,
                subKey: "settingsAutoPilot",
                name: "Settings",
                path: "/app/admin/autopilot/settings",
            }
        ]
    },
    {
        id: 4,
        key: 'locations',
        name: "Locations",
        icon: "/admin-icons/sidebar/location.svg",
        path: "/app/admin/locations",
        children: [
            {
                id: 1,
                subKey: "locationsInformation",
                name: "Location Information",
                path: "/app/admin/locations/information",
                // external: "https://irevu.com/location_settings.php"
            },
            {
                id: 2,
                subKey: "locationListings",
                name: "Listings",
                path: "/app/admin/locations/listing",
                paid: true,
                // external: "https://irevu.com/location_settings.php"
            },
            {
                id: 3,
                subKey: "reputation",
                name: "Reputation",
                path: "/app/admin/locations/reputation",
                // external: "https://irevu.com/reputation.php"
            }
        ]
    },
    {
        id: 5,
        key: 'analysis_report',
        name: 'Analysis Report',
        icon: "/admin-icons/sidebar/analysis_report.svg",
        path: "/app/admin/analysis_report",
        paid: true,
    },
    {
        id: 6,
        key: 'competitor_analysis',
        name: 'Competitor Analysis',
        icon: "/admin-icons/sidebar/competitor_analysis.svg",
        path: "/app/admin/competitor_analysis",
        paid: true,
    },
    {
        id: 7,
        key: 'social_automation',
        name: "Social Automation",
        icon: "/admin-icons/sidebar/social_media_automation.svg",
        path: "/app/admin/social_automation",
        paid: true,
        children: [
            {
                id: 1,
                subKey: "social_media_stats",
                name: "Social Media Stats",
                path: '/app/admin/goto/dashboard'
            },
            {
                id: 2,
                subKey: "schedule_reviews",
                name: "Schedule Reviews",
                path: '/app/admin/goto/reviews'
            },
            {
                id: 3,
                subKey: "publish_to_accounts",
                name: "Publish to Accounts",
                path: '/app/admin/goto/post'
            },
            {
                id: 4,
                subKey: "schedules",
                name: "Schedules",
                path: '/app/admin/goto/schedules'
            },
            {
                id: 5,
                subKey: "connections",
                name: "Connections",
                path: '/app/admin/goto/account_manager'
            },
        ]
    },
    {
        id: 8,
        key: 'included_platform',
        name: "Included Platforms",
        icon: "/admin-icons/sidebar/platforms.svg",
        path: "/app/admin/included_platform",
        children: [
            {
                id: 1,
                subKey: "mydev_crm",
                name: "MyDev CRM",
                subtitle: "CRM",
                // external: "https://irevu.com/platforms.php?goto=crm"
                path: '/app/admin/goto/crm'
            },
            {
                id: 2,
                subKey: "sendbat",
                name: "Sendbat",
                subtitle: "SMS and Email Automation",
                // external: "https://irevu.com/platforms.php?goto=sendbat"
                path: '/app/admin/goto/usender'
            },
            {
                id: 3,
                subKey: "urless",
                name: "Urless",
                subtitle: "Short URL & QR Code Generator",
                // external: "https://irevu.com/platforms.php?goto=urless"
                path: '/app/admin/goto/urless'
            },
            {
                id: 4,
                subKey: "morsix",
                name: "Morsix",
                subtitle: "Inventory Management",
                // external: "https://irevu.com/platforms.php?goto=morsix"
                path: '/app/admin/goto/morsix'
            },
            {
                id: 5,
                subKey: "claritask",
                name: "Claritask",
                subtitle: "Project Management",
                // external: "https://irevu.com/platforms.php?goto=claritask"
                path: '/app/admin/goto/claritask'
            },
            {
                id: 6,
                subKey: "claritick",
                name: "Claritick",
                subtitle: "Ticketing System",
                // external: "https://irevu.com/platforms.php?goto=claritick"
                path: '/app/admin/goto/claritick'
            },
            {
                id: 7,
                subKey: "convosio",
                name: "Convosio",
                subtitle: "Chat System",
                // external: "https://irevu.com/platforms.php?goto=convosio"
                path: '/app/admin/goto/convosio'
            },
            {
                id: 8,
                subKey: "ipaymer",
                name: "iPaymer",
                subtitle: "Subscription System",
                // external: "https://irevu.com/platforms.php?goto=ipaymer"
                path: '/app/admin/goto/ipaymer'
            },
            {
                id: 9,
                subKey: "mydevsite",
                name: "MyDev Site",
                subtitle: "Website Builder",
                // external: "https://irevu.com/platforms.php?goto=ipaymer"
                path: '/app/admin/goto/site'
            },
            {
                id: 10,
                subKey: "formsSurveys",
                name: "Forms & Surveys",
                path: "/app/admin/goto/forms",
                subtitle: "Forms Builder",
                // external: "https://irevu.com/forms.php"
            },
        ]
    },
    {
        id: 9,
        key: 'tools',
        name: "Tools",
        icon: "/admin-icons/sidebar/tools.svg",
        path: "/app/admin/tools",
        children: [
            {
                id: 1,
                subKey: "revuShare",
                name: "RevuShare - Web",
                path: "/app/admin/tools/share",
            },
            {
                id: 2,
                subKey: "qrCode",
                name: "QRCode",
                path: "/app/admin/tools/qrcode",
                // external: "https://irevu.com/qrcode.php"
            },
            {
                id: 3,
                subKey: "reviewPage",
                name: "Review Page",
                path: "/app/admin/tools/review",
                // external: "https://irevu.com/edit_review_page.php"
            },
            {
                id: 4,
                subKey: "couponList",
                name: "Coupon Opt-Ins",
                path: "/app/admin/tools/coupons",
            }, 
            {
                id: 5,
                subKey: "couponListGenerate",
                name: "Coupon List",
                path: "/app/admin/tools/coupons-list",
            }, 
            {
                id: 6,
                subKey: "groupPage",
                name: "Group Page",
                path: "/app/admin/tools/group-page",
            },  
            {
                id: 7,
                subKey: "quickReplies",
                name: "Quick Replies",
                path: "/app/admin/tools/quick-replies",
            },
            {
                id: 8,
                subKey: "pageVisits",
                name: "Page Visits",
                path: "/app/admin/tools/page-visits",
            },
            {
                id: 9,
                subKey: "orderMarketingMaterials",
                name: "Marketing Materials",
                path: "/app/admin/marketing_materials",
            },
            // {
            //     id: 7,
            //     subKey: "connectAutomation",
            //     name: "Connect Automation",
            //     path: "/app/admin/tools/connect",
            // },
        ]
    },
    {
        id: 10,
        key: 'account',
        name: "Account",
        icon: "/admin-icons/sidebar/account.svg",
        path: "/app/admin/account",
        children: [
            {
                id: 1,
                subKey: "myAccount",
                name: "My Account",
                path: "/app/admin/account/settings",
                //external: "https://irevu.com/account.php"
            },
            {
                id: 2,
                subKey: "userManagement",
                name: "User Management",
                path: "/app/admin/account/management",
                // external: "https://irevu.com/manage_users.php"
            },
            {
                id: 3,
                subKey: "invoices",
                name: "Invoices",
                path: "/app/admin/account/invoices",
                admin: true
                // external: "https://irevu.com/manage_users.php"
            },
            {
                id: 4,
                subKey: "downloads",
                name: "Downloads",
                path: "/app/admin/account/downloads",
                admin: true
                // external: "https://irevu.com/manage_users.php"
            },
            {
                id: 5,
                subKey: "notifications",
                name: "Notifications",
                path: "/app/admin/account/notifications",
            },
            {
                id: 6,
                subKey: "subscription",
                name: "Subscription",
                path: "/app/admin/subscription",
            }
        ]
    },
    {
        id: 12,
        key: 'support',
        name: "Support",
        path: "/app/admin/support",
        icon: "/admin-icons/sidebar/support.svg",
    },
]


export const paths = elements.map(el => el.path)
